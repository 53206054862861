<template>
  <card>
    <div class="custom-container">
      <navigation-top />
      <card class="shadow-none" id="resultados-evaluado">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Resultados por evaluado</b></span>
        </template>
        <DataTable
          :items="listaItems"
          :headers="resultadosHeaders"
          :keys="resultadosKeys"
          tableId="datatable_resultadosEvaluacion"
        />
      </card>
      <navigation-top />
      <card class="shadow-none" id="cargos-aceptados">
        <template v-slot:headerTitle>
          <span class="card-title my-2 h4"><b>Cargos aceptados</b></span>
        </template>
        <DataTable
          :items="listaItemsCargosAceptados"
          :headers="cargosHeaders"
          :keys="cargosKeys"
          tableId="datatable_cargos"
        />
      </card>
    </div>
  </card>
</template>

<script>
import DataTable from "../../../components/Datatable/DataTable.vue";
import { core } from "../../../config/pluginInit";
import navigationTop from "./navigation-top.vue";

export default {
  components: {
    DataTable,
    navigationTop,
  },
  name: "DataTableContainer",
  data() {
    return {
      usuario: {},
      listaItems: [],
      listaItemsCargosAceptados: [],
      resultadosKeys: [
        "nombreEvaluado",
        "promedioValoracion",
        "cargo",
        "ciclo_evaluacion_descripcion",
        "fechaTerminado",
      ],
      resultadosHeaders: [
        "Nombre Evaluado",
        "Promedio % Resultado Evaluación",
        "Cargo",
        "Ciclo Evaluación",
        "Fecha Terminado",
      ],
      cargosHeaders: ["Nombres", "Apellidos", "Cargo", "Fecha de creación"],
      cargosKeys: ["nombres", "apellidos", "cargoNombre", "fechaDeCreacion"],
      logoEmpresa: "",
      itemId: null,
    };
  },
  async mounted() {
    this.$isLoading(true);
    core.index();
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    const empresaId = this.usuario.empresa.id;
    await this.getData2(empresaId);
    await this.getDataCargos(empresaId);
    this.$isLoading(false);
  },

  methods: {
    async getDataCargos(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Reportes/VistaCargosAceptados/" + empresaId,
        });
        // console.log("res", res);
        if (res.length > 0) {
          this.listaItemsCargosAceptados = res;
        } else {
          this.listaItemsCargosAceptados = [];
        }
        // console.log("Lista cargos aceptados", this.listaItemsCargosAceptados);
      } catch (error) {
        this.listaItemsCargosAceptados = [];
        console.log("err", error);
      }
    },
    async getData2(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Reportes/ConsultaResultadosEvaluacionesV2/" + empresaId,
        });
        // console.log("res", res);
        if (res.length > 0) {
          let groupedByPerson = {};
          for (let item of res) {
            item.rolEvaluacion =
              item.rolEvaluacion == 0
                ? "AutoEvaluación"
                : item.rolEvaluacion == 1
                ? "Autoevalación + Jefe"
                : "AutoEvaluación 360";

            if (!groupedByPerson[item.nombreEvaluado]) {
              groupedByPerson[item.nombreEvaluado] = {
                valoraciones: [],
                detalles: item, // Guarda el objeto de detalles del evaluado
              };
            }
            groupedByPerson[item.nombreEvaluado].valoraciones.push(
              parseFloat(item.valoracion)
            );
          }

          this.listaItems = Object.keys(groupedByPerson).map((person) => {
            const valoraciones = groupedByPerson[person].valoraciones;
            const total = valoraciones.reduce((sum, val) => sum + val, 0);
            const average = total / valoraciones.length;
            const detalles = groupedByPerson[person].detalles;

            return {
              nombreEvaluado: person,
              cargo: detalles.cargo,
              promedioValoracion: average.toFixed(2),
              rolEvaluacion: detalles.rolEvaluacion,
              fechaTerminado: detalles.fechaTerminado,
              ciclo_evaluacion_descripcion:
                detalles.ciclo_evaluacion_descripcion,
            };
          });
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
